/** @format */

import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import NextLink from 'next/link';

interface LinkProps {
  href: string;
  children: ReactNode;
}

const StyledLink = styled.a`
  text-decoration: none;
  color: unset;
`;

function Link(props: LinkProps) {
  return (
    <NextLink href={props.href} passHref>
      <StyledLink>{props.children}</StyledLink>
    </NextLink>
  );
}

export default Link;
