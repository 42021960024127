/** @format */

import { cssVars } from '@atoms/GlobalStyles';
import styled from '@emotion/styled';

export const StyledTd = styled.td`
  padding: 8px;
  color: ${cssVars.grey};
  position: relative;
  > a {
    display: contents;
    //height: 100%;
    //width: 100%;
  }
`;
