/** @format */

import styled from '@emotion/styled';
import { usePopover } from '@react-aria/overlays';
import React, { useRef } from 'react';
import { AriaTooltipProps, useTooltip, mergeProps, useOverlayPosition, Overlay } from 'react-aria';
import { TooltipTriggerState } from 'react-stately';
import { cssVars } from '../GlobalStyles';

const arrowSize = 15;

const popUpWidth = 200;

const StyledTooltip = styled.div<TooltipProps>`
  position: absolute;
  padding: 8px;
  // //max-width: 60ch;
  border-radius: 3px;
  font-size: 12px;
  // text-align: center;
  z-index: 9999999999;
  color: ${cssVars.white};
  background: ${cssVars.grey};
  // left: 5px;
  //opacity: 0.99;
  max-width: 60ch;
  min-width: 30px;
  // margin-top: 10px;
  // padding: 5px;
  // border: 1px solid gray;
  .arrow {
    position: absolute;
    fill: ${cssVars.grey};
    stroke: ${cssVars.lightGrey};
    stroke-width: 1px;
    width: 12px;
    height: 12px;
  }

  .arrow[data-placement='top'] {
    top: 100%;
    transform: translateX(-50%);
  }

  .arrow[data-placement='bottom'] {
    bottom: 100%;
    transform: translateX(-50%) rotate(180deg);
  }

  .arrow[data-placement='left'] {
    left: 100%;
    transform: translateY(-50%) rotate(-90deg);
  }

  .arrow[data-placement='right'] {
    right: 100%;
    transform: translateY(-50%) rotate(90deg);
  }
`;

interface TooltipProps extends AriaTooltipProps {
  children: any;
  // gap: number;
  state: TooltipTriggerState;
  triggerRef: any;
}

function Tooltip({ state, ...props }: any) {
  const { tooltipProps } = useTooltip(props, state);

  let overlayRef = React.useRef<HTMLDivElement>(null);
  let { overlayProps, arrowProps, placement } = useOverlayPosition({
    placement: props.placement,
    targetRef: props.triggerRef,
    overlayRef,
    offset: 12,
    crossOffset: -12,
    isOpen: state.isOpen,
  });

  return (
    <Overlay {...overlayProps}>
      <StyledTooltip {...mergeProps(props, tooltipProps)} ref={overlayRef}>
        <svg {...arrowProps} data-placement={placement} className={'arrow'}>
          <path d="M0 0,L6 6,L12 0" />
        </svg>
        {props.children}
      </StyledTooltip>
    </Overlay>
  );
}
export default Tooltip;
